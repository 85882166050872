import MatchupScreen from "../matchup_screen";
import "./matchup_contact_screen.scss";


export default function MatchupContactScreen() {
    return (
        <MatchupScreen
            child={
                (
                    <>
                           
                    </>
                )
            }
        />
    )
}