import "./matchup_help_screen.scss";
import MatchupScreen from "../matchup_screen";
import KeyboardArrowDownIcon from "../../../assets/images/keyboard_arrow_down";
import React from "react";

export default function MatchupHelpScreen() {

    const [isOpen, setIsOpen] = React.useState(false);
    
    return (
        <MatchupScreen
            child={
                (
                    <>
                        <div className="matchup-help" id="matchup-help">
                            <h1>HELP <strong>ME</strong></h1>
                            <span className="helptext">If you can't find what you're looking for below, please feel free to contact us; <a href="mailto:admin@socializepro.co">admin@socializepro.co</a></span>
                            
                            <section id="#tableofcontents">
                                <h2>TABLE OF CONTENTS</h2>
                                <a href="#deleteaccount">1. I WANT TO DELETE MY ACCOUNT</a>
                            </section>
                            
                            <section id="#deleteaccount">
                                <h2>1. I WANT TO DELETE MY ACCOUNT</h2>
                                <span style={{marginBottom: "0px"}}>You can easily delete your account from within the application by following the steps below:</span>
                                <ul className="padding-inline">
                                    <li><span>Go to the profile page using the navigation bar and;</span></li>
                                    <li><span>Click on the settings icon on the profile page and;</span></li>
                                    <li><span>Click on the account settings section on the settings page and;</span></li>
                                    <li><span>Scroll down and click on the delete my account option and then continue by saying "I'm sure" to the "Are you sure" question.</span></li>
                                </ul>
                                <span>After all these procedures, your account will be deleted within a few seconds.</span>
                                <span>If you do not have access to our application and/or your account, please contact us to delete your account; <a href="mailto:admin@socializepro.co">admin@socializepro.co</a></span>
                                <span><i>You may need to <strong>fully answer</strong> a few questions asked by the authorities in order to establish that the account you reported to us belongs to you.</i></span>
                            </section>
                        </div>
                    </>
                )
            }
        />
    )
}