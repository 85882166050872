import { useEffect } from 'react';
import Router from './router';

function App() {

  useEffect(() => {
  }, [])

  return (
    <div className="app">
        <Router/>
    </div>
  );
}

export default App;
