import "./matchup_main_screen.scss";
import MatchupScreen from "../matchup_screen";
import matchupLoginScreenWithFrame from "../../../assets/images/ic_screenshot-matchup-login.png";
import appleIcon from "../../../assets/images/ic_icon-apple-white.png";
import playStoreIcon from "../../../assets/images/ic_icon-playstore-white.png"
import { useNavigate } from "react-router-dom";

export default function MatchupMainScreen() {

    const navigate = useNavigate();

    return (
        <MatchupScreen 
            child={
                (
                    <>
                        <div className="matchup-content-container">
                            <div className="matchup-c10">
                                {/* <span className="matchup-c11">Match & Meet</span> */}
                                <span className="matchup-c12">Find your <span>soulmate.</span></span>
                                <span className="matchup-c13">MatchUp focuses on finding your soulmate and unlike other dating applications, it adopts a non-sexual approach.</span>
                            </div>
                            <div className="matchup-c11">
                                <div className="matchup-c14 noselect">
                                    <img src={playStoreIcon} alt="" />
                                    <span>Download</span>
                                </div>

                                <div className="matchup-c14 noselect">
                                    <img src={appleIcon} alt="" />
                                    <span>Download</span>
                                </div>
                            </div>
                            <img src={matchupLoginScreenWithFrame}/>
                        </div>

                        <footer>
                            <div className="footer-container">
                                <div className="footer-links">
                                    <span onClick={() => navigate('/apps/matchup/privacy-policy')}>Privacy policy</span>
                                    <div style={{width: "1px", height: "12px", background: "rgb(160,160,160)"}}/>
                                    <span onClick={() => navigate('/apps/matchup/terms-of-use')}>Terms of use</span>
                                </div>

                                <span>SocializePro ᡣ𐭩 Made with love</span>
                            </div>
                        </footer>
                    </>
                )
            }
        />
    );
}