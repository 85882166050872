import "./matchup_screen.scss";
import React from "react";
import changeFavicon from "../../functions/change_favicon";
import matchupLogoIcon from "../../assets/images/ic_matchup-logo-icon2.png";
import MenuIcon from "../../assets/images/menu_icon";
import { Navigate, useNavigate } from "react-router-dom";
import Mailto from "../../functions/mail_to";

export default function MatchupScreen({child}) {

    React.useEffect(() => {
        document.title = "MatchUp";
        changeFavicon('/favicon-matchup.ico');
        // changeFavicon('');
        window.removeEventListener('resize', handleResize);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    function handleResize() {
        if(window.innerWidth > 500) {
            setIsMenuOpen(false);
        }
    }

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const navigate = useNavigate();

    return (
        <div className="matchup" id="matchup">
            <div className="mobile-menu" style={{display: isMenuOpen ? "block" : "none"}} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <div className="matchup-f11" onClick={(e) => e.stopPropagation()}>
                    <div className="matchup-f12">
                        <Mailto email={"admin@socializepro.co"}>
                            <span>Contact us</span>
                        </Mailto>
                    </div>
                    <div className="matchup-f12" onClick={() => navigate('/apps/matchup/help')}>
                        <span>Help</span>
                    </div>

                    <div className="matchup-f13">
                        <span>DOWNLOAD</span>
                    </div>
                </div>
            </div>
            <div className="matchup-navbar padding-inline" id="matchup-navbar">
                <div className="matchup-navbar-container">
                    <img className="matchup-logo" src={matchupLogoIcon} alt="" onClick={() => navigate('/apps/matchup')}/>
                    <div style={{flex: 1}}/>
                    <div className="matchup-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <MenuIcon/>
                    </div>
                    <li>
                        {/* <a className="noselect">
                            <span>ABOUT US</span>
                        </a> */}
                        <a href='mailto:admin@socializepro.co' className="noselect">
                            <span>CONTACT US</span>
                        </a>
                        <a className="noselect" onClick={() => navigate('/apps/matchup/help')}>
                            <span>HELP</span>
                            {/* <KeyboardArrowDownIcon fillColor={"rgb(42, 42, 42)"}/> */}
                        </a>
                    </li>
                    <div className="download-button noselect">
                        <span>DOWNLOAD</span>
                    </div>
                </div>
            </div>

            <div className="matchup-content padding-inline">
                {child}
            </div>
        </div>  
    )
}   