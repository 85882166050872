import React from "react";
import changeFavicon from "../../functions/change_favicon";

export default function MainScreen() {

    React.useEffect(() => {
        changeFavicon('/favicon.ico');
    }, []);

    return (
        <div className="main_screen" id="main_screen">

        </div>
    )
}