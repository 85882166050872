import { Routes, Route, Navigate } from "react-router-dom";
import { FirebaseAuthContext } from "./utils/auth_provider";
import { useContext } from "react";
import MatchupMainScreen from "./screens/matchup/main/matchup_main_screen";
import MainScreen from "./screens/main/main_screen";
import MatchupContactScreen from "./screens/matchup/contact/matchup_contact_screen";
import MatchupHelpScreen from "./screens/matchup/help/matchup_help_screen";
import MatchupPrivacyPolicyScreen from "./screens/matchup/privacy_policy/matchup_privacy_policy_screen";
import MatchupTermsOfUseScreen from "./screens/matchup/terms_of_use/matchup_terms_of_use_screen";

// const ProtectedRoute = ({children}) => {
//     const authUser = useContext(FirebaseAuthContext)
//     if(!authUser) {
      
//         return (
//             <Navigate to={"/müşterilerim"}></Navigate>
//         )
//     }

//     return children
// }

export default function Router() {

    return (
        <Routes>
            <Route path="/" element={<MainScreen/>}/>
            <Route path="/apps/matchup" element={<MatchupMainScreen/>}/>
            <Route path="/apps/matchup/privacy-policy" element={<MatchupPrivacyPolicyScreen/>}/>
            <Route path="/apps/matchup/terms-of-use" element={<MatchupTermsOfUseScreen/>}/>
            <Route path="/apps/matchup/contact-us" element={<MatchupContactScreen/>}/>
            <Route path="/apps/matchup/help" element={<MatchupHelpScreen/>}/>
            {/* <Route path="/kontrol paneli/*" element={<Panel/>}/>
            <Route path="/whatsapp/*" element={<Whatsapp/>}/>
            <Route path="/try" element={<Try />}/> */}
        </Routes>
    )
}